import { FETCH_INVOICE_FAILURE, FETCH_INVOICE_REQUEST, FETCH_INVOICE_SUCCESS } from "../../actions/invoices/single/invoiceTypes"

const initialState = {
    loading: false,
    invoice: [],
    error: ''
}

const invoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INVOICE_REQUEST:
            return {
                loading: true,
                invoice: [],
                error: ''
            }
        case FETCH_INVOICE_SUCCESS:
            return {
                loading: false,
                invoice: action.payload,
                error: ''
            }
        case FETCH_INVOICE_FAILURE:
            return {
                loading: false,
                invoice: [],
                error: action.payload
            }
        default: return state
    }
}

export default invoiceReducer
