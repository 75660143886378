// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import videos from './resources/videoReducer.js'
import video from './resources/singleVideoReducer.js'
import books from './resources/bookReducer.js'
import book from './resources/singleBookReducer.js'
import articles from './resources/articleReducer.js'
import article from './resources/singleArticleReducer.js'
import audios from './resources/audioReducer.js'
import audio from './resources/singleAudioReducer.js'
import trainings from './resources/trainingReducer.js'
import skillsets from './skillsetReducer'
import fetchinvoices from './membership/invoiceReducer.js'
import fetchinvoice from './membership/singleInvoiceReducer.js'
import fetchreceipts from './receipts/receiptReducer'
import fetchreceipt from './receipts/singleReceiptReducer'
import fetchevents from './calendar/events/fetchReducer.js'
import fetchcontacts from './chats/contacts/fetchReducer.js'
import singlecluster from './singleClusterReducer'
import fetchconversations from './chats/conversations/fetchReducer.js'
import singleconversation from './chats/conversations/fetchSingleReducer.js'
import fetchmembers from './membersReducer.js'
import singletraining from './resources/singleTrainingReducer.js'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  videos,
  video,
  books,
  book,
  articles,
  article,
  audios,
  audio,
  trainings,
  skillsets,
  fetchinvoices,
  fetchinvoice,
  fetchevents,
  fetchcontacts,
  singlecluster,
  fetchconversations,
  singleconversation,
  fetchmembers,
  singletraining,
  fetchreceipts,
  fetchreceipt
})

export default rootReducer
