import { FETCH_AUDIO_FAILURE, FETCH_AUDIO_REQUEST, FETCH_AUDIO_SUCCESS } from "../../actions/resources/audios/single/audioTypes"

const initialState = {
    loading: false,
    audio: [],
    error: ''
}

const audioReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_AUDIO_REQUEST:
            return {
                loading: true,
                audio: [],
                error: ''
            }
        case FETCH_AUDIO_SUCCESS:
            return {
                loading: false,
                audio: action.payload,
                error: ''
            }
        case FETCH_AUDIO_FAILURE:
            return {
                loading: false,
                audio: [],
                error: action.payload
            }
        default: return state
    }
}

export default audioReducer
