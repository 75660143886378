import { FETCH_CONVERSATION_FAILURE, FETCH_CONVERSATION_REQUEST, FETCH_CONVERSATION_SUCCESS } from "../../../actions/chats/conversations/single/conversationTypes"

const initialState = {
    loading: false,
    conversation: [],
    error: ''
}

const fetchSingleReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONVERSATION_REQUEST:
            return {
                loading: true,
                conversation: [],
                error: ''
            }
        case FETCH_CONVERSATION_SUCCESS:
            return {
                loading: false,
                conversation: action.payload,
                error: ''
            }
        case FETCH_CONVERSATION_FAILURE:
            return {
                loading: false,
                conversation: [],
                error: action.payload
            }
        default: return state
    }
}

export default fetchSingleReducer
