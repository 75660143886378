import { FETCH_MEMBERS_FAILURE, FETCH_MEMBERS_REQUEST, FETCH_MEMBERS_SUCCESS } from "../actions/members/list/memberTypes"

const initialState = {
    loading: false,
    members: [],
    error: ''
}

const memberReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MEMBERS_REQUEST:
            return {
                loading: true,
                members: [],
                error: ''
            }
        case FETCH_MEMBERS_SUCCESS:
            return {
                loading: false,
                members: action.payload,
                error: ''
            }
        case FETCH_MEMBERS_FAILURE:
            return {
                loading: false,
                members: [],
                error: action.payload
            }
        default: return state
    }
}

export default memberReducer
