import { FETCH_BOOK_FAILURE, FETCH_BOOK_REQUEST, FETCH_BOOK_SUCCESS } from "../../actions/resources/books/single/bookTypes"

const initialState = {
    loading: false,
    book: {},
    error: ''
}

const bookReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BOOK_REQUEST:
            return {
                loading: true,
                book: {},
                error: ''
            }
        case FETCH_BOOK_SUCCESS:
            return {
                loading: false,
                book: action.payload,
                error: ''
            }
        case FETCH_BOOK_FAILURE:
            return {
                loading: false,
                book: {},
                error: action.payload
            }
        default: return state
    }
}

export default bookReducer
