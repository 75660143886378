import { FETCH_AUDIOS_FAILURE, FETCH_AUDIOS_REQUEST, FETCH_AUDIOS_SUCCESS } from "../../actions/resources/audios/list/audioTypes"

const initialState = {
    loading: false,
    audios: [],
    error: ''
}

const audioReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_AUDIOS_REQUEST:
            return {
                loading: true,
                audios: [],
                error: ''
            }
        case FETCH_AUDIOS_SUCCESS:
            return {
                loading: false,
                audios: action.payload,
                error: ''
            }
        case FETCH_AUDIOS_FAILURE:
            return {
                loading: false,
                audios: [],
                error: action.payload
            }
        default: return state
    }
}

export default audioReducer
