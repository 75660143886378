import { FETCH_TRAINING_FAILURE, FETCH_TRAINING_REQUEST, FETCH_TRAINING_SUCCESS } from "../../actions/resources/trainings/single/trainingTypes"

const initialState = {
    loading: false,
    training: [],
    error: ''
}

const trainingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TRAINING_REQUEST:
            return {
                loading: true,
                training: [],
                error: ''
            }
        case FETCH_TRAINING_SUCCESS:
            return {
                loading: false,
                training: action.payload,
                error: ''
            }
        case FETCH_TRAINING_FAILURE:
            return {
                loading: false,
                training: [],
                error: action.payload
            }
        default: return state
    }
}

export default trainingReducer
