import { FETCH_ARTICLE_FAILURE, FETCH_ARTICLE_REQUEST, FETCH_ARTICLE_SUCCESS } from "../../actions/resources/articles/single/articleTypes"

const initialState = {
    loading: false,
    article: [],
    error: ''
}

const articleReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ARTICLE_REQUEST:
            return {
                loading: true,
                article: [],
                error: ''
            }
        case FETCH_ARTICLE_SUCCESS:
            return {
                loading: false,
                article: action.payload,
                error: ''
            }
        case FETCH_ARTICLE_FAILURE:
            return {
                loading: false,
                article: [],
                error: action.payload
            }
        default: return state
    }
}

export default articleReducer
