import { FETCH_RECEIPT_FAILURE, FETCH_RECEIPT_REQUEST, FETCH_RECEIPT_SUCCESS } from "../../actions/receipts/single/receiptTypes"

const initialState = {
    loading: false,
    receipt: [],
    error: ''
}

const receiptReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RECEIPT_REQUEST:
            return {
                loading: true,
                receipt: [],
                error: ''
            }
        case FETCH_RECEIPT_SUCCESS:
            return {
                loading: false,
                receipt: action.payload,
                error: ''
            }
        case FETCH_RECEIPT_FAILURE:
            return {
                loading: false,
                receipt: [],
                error: action.payload
            }
        default: return state
    }
}

export default receiptReducer
