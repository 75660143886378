import { FETCH_CLUSTER_FAILURE, FETCH_CLUSTER_REQUEST, FETCH_CLUSTER_SUCCESS } from "../actions/cluster/single/clusterTypes"

const initialState = {
    loading: false,
    cluster: [],
    error: ''
}

const singleClusterReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CLUSTER_REQUEST:
            return {
                loading: true,
                cluster: [],
                error: ''
            }
        case FETCH_CLUSTER_SUCCESS:
            return {
                loading: false,
                cluster: action.payload,
                error: ''
            }
        case FETCH_CLUSTER_FAILURE:
            return {
                loading: false,
                cluster: [],
                error: action.payload
            }
        default: return state
    }
}

export default singleClusterReducer
