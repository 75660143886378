import { FETCH_VIDEOS_FAILURE, FETCH_VIDEOS_REQUEST, FETCH_VIDEOS_SUCCESS } from "../../actions/resources/videos/list/videoTypes"

const initialState = {
    loading: false,
    videos: [],
    error: ''
}

const videoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VIDEOS_REQUEST:
            return {
                loading: true,
                videos: [],
                error: ''
            }
        case FETCH_VIDEOS_SUCCESS:
            return {
                loading: false,
                videos: action.payload,
                error: ''
            }
        case FETCH_VIDEOS_FAILURE:
            return {
                loading: false,
                videos: [],
                error: action.payload
            }
        default: return state
    }
}

export default videoReducer
