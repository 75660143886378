import { FETCH_BOOKS_FAILURE, FETCH_BOOKS_REQUEST, FETCH_BOOKS_SUCCESS } from "../../actions/resources/books/list/bookTypes"

const initialState = {
    loading: false,
    books: [],
    error: ''
}

const bookReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BOOKS_REQUEST:
            return {
                loading: true,
                books: [],
                error: ''
            }
        case FETCH_BOOKS_SUCCESS:
            return {
                loading: false,
                books: action.payload,
                error: ''
            }
        case FETCH_BOOKS_FAILURE:
            return {
                loading: false,
                books: [],
                error: action.payload
            }
        default: return state
    }
}

export default bookReducer
