import { FETCH_CONVERSATIONS_FAILURE, FETCH_CONVERSATIONS_REQUEST, FETCH_CONVERSATIONS_SUCCESS } from "../../../actions/chats/conversations/list/conversationTypes"

const initialState = {
    loading: false,
    conversations: [],
    error: ''
}

const fetchReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONVERSATIONS_REQUEST:
            return {
                loading: true,
                conversations: [],
                error: ''
            }
        case FETCH_CONVERSATIONS_SUCCESS:
            return {
                loading: false,
                conversations: action.payload,
                error: ''
            }
        case FETCH_CONVERSATIONS_FAILURE:
            return {
                loading: false,
                conversations: [],
                error: action.payload
            }
        default: return state
    }
}

export default fetchReducer
