import { FETCH_EVENTS_FAILURE, FETCH_EVENTS_REQUEST, FETCH_EVENTS_SUCCESS } from "../../../actions/calendar/events/list/eventTypes"

const initialState = {
    loading: false,
    events: [],
    error: ''
}

const fetchReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EVENTS_REQUEST:
            return {
                loading: true,
                events: [],
                error: ''
            }
        case FETCH_EVENTS_SUCCESS:
            return {
                loading: false,
                events: action.payload,
                error: ''
            }
        case FETCH_EVENTS_FAILURE:
            return {
                loading: false,
                events: [],
                error: action.payload
            }
        default: return state
    }
}

export default fetchReducer
