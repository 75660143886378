import { FETCH_RECEIPTS_FAILURE, FETCH_RECEIPTS_REQUEST, FETCH_RECEIPTS_SUCCESS } from "../../actions/receipts/list/receiptTypes"

const initialState = {
    loading: false,
    receipts: [],
    error: ''
}

const receiptsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RECEIPTS_REQUEST:
            return {
                loading: true,
                receipts: [],
                error: ''
            }
        case FETCH_RECEIPTS_SUCCESS:
            return {
                loading: false,
                receipts: action.payload,
                error: ''
            }
        case FETCH_RECEIPTS_FAILURE:
            return {
                loading: false,
                receipts: [],
                error: action.payload
            }
        default: return state
    }
}

export default receiptsReducer
