import { FETCH_VIDEO_FAILURE, FETCH_VIDEO_REQUEST, FETCH_VIDEO_SUCCESS } from "../../actions/resources/videos/single/videoTypes"

const initialState = {
    loading: false,
    video: [],
    error: ''
}

const videoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VIDEO_REQUEST:
            return {
                loading: true,
                video: [],
                error: ''
            }
        case FETCH_VIDEO_SUCCESS:
            return {
                loading: false,
                video: action.payload,
                error: ''
            }
        case FETCH_VIDEO_FAILURE:
            return {
                loading: false,
                video: [],
                error: action.payload
            }
        default: return state
    }
}

export default videoReducer
