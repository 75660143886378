import { FETCH_SKILLSETS_FAILURE, FETCH_SKILLSETS_REQUEST, FETCH_SKILLSETS_SUCCESS } from "../actions/skillset/list/skillsetTypes"

const initialState = {
    loading: false,
    skillsets: [],
    error: ''
}

const skillsetReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SKILLSETS_REQUEST:
            return {
                loading: true,
                skillsets: [],
                error: ''
            }
        case FETCH_SKILLSETS_SUCCESS:
            return {
                loading: false,
                skillsets: action.payload,
                error: ''
            }
        case FETCH_SKILLSETS_FAILURE:
            return {
                loading: false,
                skillsets: [],
                error: action.payload
            }
        default: return state
    }
}

export default skillsetReducer
