import { FETCH_CONTACTS_FAILURE, FETCH_CONTACTS_REQUEST, FETCH_CONTACTS_SUCCESS } from "../../../actions/chats/contacts/list/contactTypes"

const initialState = {
    loading: false,
    contacts: [],
    error: ''
}

const fetchReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONTACTS_REQUEST:
            return {
                loading: true,
                contacts: [],
                error: ''
            }
        case FETCH_CONTACTS_SUCCESS:
            return {
                loading: false,
                contacts: action.payload,
                error: ''
            }
        case FETCH_CONTACTS_FAILURE:
            return {
                loading: false,
                contacts: [],
                error: action.payload
            }
        default: return state
    }
}

export default fetchReducer
