import { FETCH_TRAININGS_FAILURE, FETCH_TRAININGS_REQUEST, FETCH_TRAININGS_SUCCESS } from "../../actions/resources/trainings/list/trainingTypes"

const initialState = {
    loading: false,
    trainings: [],
    error: ''
}

const trainingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TRAININGS_REQUEST:
            return {
                loading: true,
                trainings: [],
                error: ''
            }
        case FETCH_TRAININGS_SUCCESS:
            return {
                loading: false,
                trainings: action.payload,
                error: ''
            }
        case FETCH_TRAININGS_FAILURE:
            return {
                loading: false,
                trainings: [],
                error: action.payload
            }
        default: return state
    }
}

export default trainingReducer
